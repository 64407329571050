/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

import './static/sass/front.scss';
import $ from "jquery/dist/jquery";
global.$ = $;
global.jQuery = $;
window.$ = $;
window.jQuery = $;

import 'bootstrap/js/dist/tooltip.js';
import 'bootstrap/js/dist/popover.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import Fancybox from "@fancyapps/ui/dist/fancybox/fancybox.umd";

global.Fancybox = Fancybox;
window.Fancybox = Fancybox;

import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "@fancyapps/ui/dist/fancybox/fancybox.umd";

import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import './static/js/jquery.modal.min.js';
import './static/js/common.js';


// start the Stimulus application
//import './bootstrap';
