//DOM STRINGS OBJECT
const DOM = {
    timelineDate: document.querySelectorAll('.timeline__date'),
    timelineElem: document.querySelectorAll('.timeline__elem'),
    timelineBar: document.querySelector('.timeline__bar') };


//TIMELINE ELEM SET DIRECTION TO EVENT ITEMS (left or right oriented)

//getting direction from .timeline-elem
const __getDir = function(timelineElem){

    if (timelineElem.classList.contains('timeline__elem--left')) {
        return 'left';
    } else if (timelineElem.classList.contains('timeline__elem--right')) {
        return 'right';
    }

};

const setDirEvent = function(){

    DOM.timelineElem.forEach(function(elem) {

        const direction = __getDir(elem);

        const timelineEvent = elem.querySelector('.timeline__event');

        timelineEvent.classList.add('timeline__event--'+direction);

    });

};

//TIMELINE ELEM DATE STYLES (background)
const __getBGImage = function(){

    const compStyle = getComputedStyle(DOM.timelineBar);

    return compStyle.backgroundImage;

};

const __getBGSize_height = function(){

    const timebarHeight = DOM.timelineBar.offsetHeight;

    return timebarHeight;

};

const __getBGPos_y = function(dateElem){

    const timelinebarBox = DOM.timelineBar.getBoundingClientRect();

    const dateBox = dateElem.getBoundingClientRect();

    const pos_y = dateBox.top - timelinebarBox.top;

    return pos_y;

};

const setDateBG = function(){

    const bgImg = __getBGImage();

    const bgHeight = __getBGSize_height();

    DOM.timelineDate.forEach(function(elem) {

        //setting bgImage
        elem.style.backgroundImage = bgImg;

        //setting bgSize
        elem.style.backgroundSize = '100% '+bgHeight+'px';

        //setting bgPosition
        const dateOffset = __getBGPos_y(elem);

        elem.style.backgroundPosition = '0 -'+dateOffset+'px';

    });

};

function displayPopinLogin(redirectUrl){

    Fancybox.show([
        {
            src: '/popin-login?redirecturl='+encodeURIComponent(redirectUrl),
            type: "iframe",
            preload: false,
            width: 600,
            height: 600,
        },
    ]);
}


$(document).ready(function(){

    $('.popin-login').click(function(e){
        e.preventDefault();

        displayPopinLogin(window.location.href);
    })

    $(document).on('click','#hidden-content-fb button',function () {
        $('#hidden-content-fb button').removeClass('call-action');
        $(this).addClass('call-action');
        var index = $('#hidden-content-fb button').index(this);
        $('#hidden-content-fb img').hide();
        $('#hidden-content-fb img').eq(index).show();
    })
/*
    //setting direction class to the timeline event block
    setDirEvent();

    //set date background styles
    setDateBG();

*/

});